<template>
  <b-modal id="show" hide-footer hide-header centered size="lg">
    <div class="store__product__modal" id="dialog">
      <button
        @click="$bvModal.hide('show')"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true"></span>
      </button>
      <div class="store__product__modal__item">
        <div class="store__product__modal__slider">
          <h4 class="store__product__modal__title">{{item.translations.find(({locale}) => locale === $i18n.locale).name}}</h4>
          <div class="store__product__modal__count">Доступно товаров: {{item.count}}</div>

          <div class="store__product__modal__slider__carousel">
            <div class="store__product__modal__slider__carousel__arrow prev" @click="prevImage"></div>
            <div class="store__product__modal__slider__carousel__arrow next" @click="nextImage"></div>
            <div class="store__product__modal__slider__carousel__current">
              <img :src="getImageUrl(currentImg)"/>
            </div>
            <div class="store__product__modal__slider__carousel__pagination">
              <button
                class="store__product__modal__slider__carousel__pagination__item"
                v-for="img in item.gallery.sort((a, b) => a.is_main > b.is_main ? -1 : a.is_main < b.is_main ? 1 : 0)"
                @click="setCurrentImage(img.image)"
              >
                <img :src="getImageUrl(img.image)" />
              </button>
            </div>
          </div>
        </div>
        <div class="store__product__modal__info">
          <div class="store__product__modal__info__price">
            {{ $t('store.products.price') }}:
            <span>{{ item.price }}</span>
          </div>

          <div class="store__product__modal__info__tabs">
            <button
              :class="{active: currentTab === 'description'}"
              @click="currentTab = 'description'"
            >Описание</button>
            <button
              :class="{active: currentTab === 'properties'}"
              @click="currentTab = 'properties'"
            >Характеристика</button>
          </div>
          <div class="store__product__modal__info__tabs__content">
<!--            <div v-show="currentTab === 'description'" v-html="item.translations.find(({locale}) => locale === $i18n.locale).description" />-->
            <p v-show="currentTab === 'description'">
              {{item.translations.find(({locale}) => locale === $i18n.locale).short_description}}
            </p>
            <ul v-show="currentTab === 'properties'">
              <li v-for="value in item.property_values" :key="value.id">
                <span>{{value.key.translations.find(({locale}) => locale === $i18n.locale).name}}</span>
                <span>{{value.translations.find(({locale}) => locale === $i18n.locale).name}}</span>
              </li>
            </ul>
          </div>

          <button class="btn__main btn__main_accent btn__main_full-width" @click="addInCart(item.id)">Добавить в корзину</button>
          <button class="btn__main btn__main_secondary btn__main_full-width">Купить сейчас</button>
        </div>
      </div>
      <router-link
        :to="{
          name: 'goods-details',
          params: { id: item.id }
        }"
        class="store__product__modal__more"
      >
        Больше информации о товаре
      </router-link>
    </div>
  </b-modal>
</template>

<script>
  import Vue from "vue";

  export default {
    name: "ProductCardModal",
    props: {
      item: {
        required: true
      }
    },
    data() {
      return {
        manualNavigation: 0,
        currentImg: null,
        currentTab: 'description',
      };
    },
    methods: {
      getImageUrl(fileName) {
        return `${API_ROOT}/images/goods/${fileName}`;
      },
      prevImage() {
        const currentIndex = this.item.gallery.findIndex(({ image }) => image === this.currentImg);
        this.currentImg = currentIndex === 0
          ? this.item.gallery[this.item.gallery.length - 1].image
          : this.item.gallery[currentIndex - 1].image;
      },
      nextImage() {
        const currentIndex = this.item.gallery.findIndex(({ image }) => image === this.currentImg);
        this.currentImg = currentIndex === this.item.gallery.length - 1
          ? this.item.gallery[0].image
          : this.item.gallery[currentIndex + 1].image;
      },
      setCurrentImage(image) {
        this.currentImg = image;
      },
      addInCart(productId) {
        const params = { good_id: productId };
        const basketId = localStorage.getItem('basket_id');
        if (!!basketId) {
          params['basket_id'] = basketId;
        }

        this.$http.post(`${API_ROOT}/api/shop/basket/add`, params)
          .then(({ body }) => {
            if (!basketId) {
              localStorage.setItem('basket_id', body.data.id);
            }

            Vue.toastr({
              message: 'Success',
              description: 'Product add to cart',
              type: 'success'
            });
          })
          // .then(() => this.quantityGoodsInBasket())
          .catch(err => console.log(err));
      },
    },
    mounted() {
      this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
        if (modalId === 'show') {
          this.currentImg = this.item.gallery.find(({ is_main }) => !!is_main).image;
        }
      });

      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === 'show') {
          this.currentImg = null;
        }
      });
    }
  }
</script>

<style scoped>

</style>
