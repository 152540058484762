<template>
  <div class="store__product">
    <div class="store__product__image__wrapper">
      <img
        :src="getImagePath(item.gallery.find(({is_main}) => !!is_main)?.image)"
        @error="setPlaceholder"
        class="store__product__image"
        :alt="item.translations.find(({locale}) => locale === $i18n.locale).name"
      />
      <div class="store__product__quick_view store__product__quick_view_mobile">
        <button class="store__product__quick_view__button" @click="show(item)">
          {{ $t('store.products.quick_view') }}
        </button>
      </div>
    </div>
    <div class="store__product__info">
      <router-link
        :to="{
          name: 'goods-details',
          params: { id: item.id }
        }"
        class="store__product__title"
      >
        {{item.translations.find(({locale}) => locale === $i18n.locale).name}}
      </router-link>
      <span class="store__product__count">{{ $t('store.products.available') }}: {{ item.count }}</span>
    </div>
    <div class="store__product__footer">
      <div class="store__product__price">{{ item.price }}</div>
      <button
        v-if="isAuthenticated"
        class="store__product__button"
        @click="addInCart(item.id)"
        :disabled="item.count <= 0"
      >{{ item.count <= 0 ? $t('store.products.not_in_stock') : $t('store.products.in_cart') }}</button>
    </div>
  </div>
</template>

<script>
  import ProductCardModal from "./ProductCardModal";

  export default {
    name: "ProductCard",
    components: {
      ProductCardModal
    },
    data() {
      return {
        currentModalItem: null,
        isAuthenticated: !!localStorage.getItem('token'),
        noPhoto: require('@/assets/images/no_photo.png')
      }
    },
    props: {
      item: {
        required: true
      }
    },
    methods: {
      getImagePath(path) {
        return path ? `${API_ROOT}/images/goods/${path}` : this.noPhoto;
      },
      setPlaceholder(event) {
        event.target.src = this.noPhoto;
      },
      show(product) {
        this.$emit('show', product);
      },
      addInCart(productId) {
        this.$emit('addInCart', productId);
      },
    }
  }
</script>

<style scoped>

</style>
