<template>
  <div class="store_block">
    <div class="store_header_block">
      <div class="store_header_container">
        <div class="store_header_label">
          {{ $t('Shop_online') }}
        </div>
        <div class="store_header_basket_block">
<!--          <router-link to="/delivery" class="store_header_delivery">-->
<!--            <div class="store_header_delivery_img">-->
<!--              <div class="store_header_delivery_number">{{ deliveryNumber }}</div>-->
<!--            </div>-->
<!--            <div class="store_header_delivery_text">{{ $t('Delivery') }}</div>-->
<!--          </router-link>-->
          <router-link to="/store/basket" class="store_header_basket">
            <div class="store_header_basket_img">
              <div class="store_header_basket_number">{{ countOfGoods }}</div>
            </div>
            <div class="store_header_basket_text">{{ $t('Basket') }}</div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="store_main_block">
      <div :class="[isAuthenticated ? basicClassStore  : '', activeClassStore]">
        <div class="store_filter_container" v-if="isAuthenticated">
          <div class="store_coins" v-if="isAuthenticated">
            <div class="store_coins_text">{{ $t('Number_of_coins') }}:</div>
            <div class="store_coins_quantity">{{ activeUserCoins }}</div>
          </div>
<!--          <div class="store_filter_label">{{ $t('Sort_and_filter') }}</div>-->
<!--          <div class="store_list_sorting">-->
<!--            <div class="store_list_sorting_label">{{ $t('Sorting') }}</div>-->
<!--            <div class="store_list_radio_block">-->
<!--              <div class="store_radio_item">-->
<!--                <input type="radio" name="sortStore" id="newest" class="store_list_radio"/>-->
<!--                <label for="newest" class="store_radio_label">{{ $t('Popular') }}</label>-->
<!--              </div>-->
<!--              <div class="store_radio_item">-->
<!--                <input type="radio" name="sortStore" id="popular" class="store_list_radio"/>-->
<!--                <label for="popular" class="store_radio_label">{{ $t('Descending_prices') }}</label>-->
<!--              </div>-->
<!--              <div class="store_radio_item">-->
<!--                <input type="radio" name="sortStore" id="popular" class="store_list_radio"/>-->
<!--                <label for="popular" class="store_radio_label">{{ $t('Ascending_prices') }}</label>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="store_catalog">-->
<!--            <div class="store_catalog_label" @click="showList = !showList">{{ $t('Catalog') }}</div>-->
<!--            <div class="store_catalog_search_container" v-show="showList">-->
<!--              <input placeholder="Поиск по товарам"/>-->
<!--            </div>-->
<!--            <div class="store_catalog_list_goods" v-show="showList">-->

<!--              <el-checkbox-group v-model="checkList" class="store_catalog_list_goods">-->
<!--                <el-checkbox class="store_catalog_product" label="electronics">{{ $t('Electronics') }}</el-checkbox>-->
<!--                <el-checkbox class="store_catalog_product" label="accessories">{{ $t('Accessories') }}</el-checkbox>-->
<!--                <el-checkbox class="store_catalog_product" label="sport">{{ $t('Sport') }}</el-checkbox>-->
<!--                <el-checkbox class="store_catalog_product" label="books">{{ $t('Books') }}</el-checkbox>-->
<!--                <el-checkbox class="store_catalog_product" label="stationery">{{ $t('Stationery') }}</el-checkbox>-->
<!--                <el-checkbox class="store_catalog_product" label="clothes">{{ $t('Clothes') }}</el-checkbox>-->
<!--              </el-checkbox-group>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="store_list_goods">
          <div class="store__product__list">
            <ProductCard
              v-for="item in listOfGoods"
              :item="item"
              @show="onShowModal"
              @addInCart="buyGood"
            />
          </div>

          <!-- todo: refactor -->
          <div v-show="showModal" class="store_product_modal" v-if="currentItem">
            <div class="store_product_modal_back"></div>
            <div class="store_product_modal_front">
              <router-link :to="goodsUrl + currentItem.id" class="store_product_modal_more_info">{{ $t('More_information_about_the_product') }}
              </router-link>
              <div class="store_product_modal_img_block">
                <div class="store_product_modal_img_title">
                  {{currentItem.translations.find(({locale}) => locale === currentLocale).name}}
                </div>
                <div class="store_product_modal_img_available">
                  {{ $t('Available_items') }}: {{ currentItem.count }}
                </div>
                <div class="store_product_modal_img_container">
                  <div class="store_one_product_desc_slider_container_arrow_right" @click="showNextImage"></div>
                  <div class="store_one_product_desc_slider_container_arrow_left" @click="showPrevImage"></div>
                  <div class="store_product_modal_img_miniatures">
                    <img
                      v-for="(img, key) in currentItem.gallery"
                      class="store_product_modal_img_miniatures_item"
                      :class="[activeImg(img.image) ? activeClass : '', basicClass]"
                      :src="backUrl + img.image"
                      :key="key"
                      @click="currentImg = img.image"
                      @error="setAltImg"
                    />
                  </div>
                  <div class="store_product_modal_img_main">
                    <img
                      :src="backUrl + currentImg"
                      width="167"
                      height="167"
                      @error="setAltImg"
                    />
                  </div>
                </div>
              </div>
              <div class="store_product_modal_desc">
                <div class="store_product_modal_desc_price">
                  <div>{{ $t('Price') }}:</div>
                  <div class="store_product_modal_desc_price_num">{{ currentItem.price }}</div>
                </div>
                <div class="store_product_modal_desc_tabs">
                  <div
                    class="store_product_modal_tab"
                    :class="{'store_product_modal_tab_active':currentModalTab==='description'}"
                    @click="currentModalTab = 'description'"
                  >
                    {{ $t('Description') }}
                  </div>
                  <div
                    class="store_product_modal_tab"
                    :class="{'store_product_modal_tab_active':currentModalTab==='characteristic'}"
                    @click="currentModalTab = 'characteristic'"
                  >
                    {{ $t('Characteristic') }}
                  </div>
                </div>
                <div v-if="currentModalTab === 'description'">
                  <div class="store_product_modal_tab_desc">
                    {{currentItem.translations.find(({locale}) => locale === currentLocale).short_description}}
                  </div>
                </div>
                <div v-if="currentModalTab === 'characteristic'">
                  <div class="store_product_modal_tab_char">
                    <div class="store_product_modal_tab_char_item" v-for="(value, key) in currentItem.property_values" :key="key">
                      <div class="store_product_modal_tab_char_category">
                        {{value.key.translations.find(({locale}) => locale === currentLocale).name}}
                      </div>
                      <div class="store_product_modal_tab_char_text">
                        {{value.translations.find(({locale}) => locale === currentLocale).name}}
                      </div>
                    </div>
                  </div>
                </div>
                <button class="store_product_modal_btn_buy" @click="buyGood(currentItem.id)">{{ $t('Add_to_Basket') }}</button>
                <button class="store_product_modal_btn_buy_now" @click="buyGood(currentItem.id,true)">{{ $t('Buy_now') }}</button>
              </div>
              <div class="store_product_close_form" @click="closeModalWindow"></div>
            </div>
          </div>

          <ProductCardModal v-if="currentModalItem" :item="currentModalItem" />

          <div class="store_pagination">
            <MyPagination
              :totalPages="totalPages"
              :total="total"
              :perPage="perPage"
              :currentPage="currentPage"
              @pagechanged="onPageChange"
            />
          </div>

          <div v-show="showModalSuccessfullyAddToBasket" class="store_modal">
            <div class="store_modal_back" @click="showModal = false">
            </div>
            <div class="store_modal_front">
              <div class="store_modal_front_title">{{ $t('The_product_has_been_successfully_added_to_the_cart') }}</div>
              <div class="store_modal_front_icon"></div>
              <router-link to="/store/basket" class="store_modal_front_btn">{{ $t('Go_to_cart') }}</router-link>
              <div class="store_modal_close_form" @click="showModalSuccessfullyAddToBasket = false"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import MyPagination from "@/components/MyPagination";
import ProductCard from "./components/ProductCard";
import ProductCardModal from "./components/ProductCardModal";

export default {
  name: "Store",
  components: {
    MyPagination,
    ProductCard,
    ProductCardModal,
  },
  data() {
    return {
      currentModalItem: null,

      showModal: false,
      showModalSuccessfullyAddToBasket: false,
      currentModalTab: "description",
      basketNumber: 10,
      deliveryNumber: 0,
      currentPage: 2,
      perPage: 0,
      total: 0,
      totalPages: 0,
      listOfGoods: [],
      storeData: [],
      backUrl: window.API_ROOT + '/images/goods/',
      goodsUrl: '/store/',
      currentItem: null,
      checkList: [],
      showList: false,
      basketData: [],
      userCoins: null,
      currentImg: '',
      activeClass: 'store_product_modal_img_miniatures_item_active',
      basicClass : "store_product_modal_img_miniatures_item",
      noPhoto: require('@/assets/images/no_photo.png'),
      images: [],
      isAuthenticated: !!localStorage.getItem('token'),
      activeClassStore : 'store_main_container_not_auth',
      basicClassStore : 'store_main_container',
      currentLocale : this.$i18n.locale,
    }
  },
  methods: {
    onShowModal(product) {
      // this.currentModalItem = product;
      // this.$bvModal.show('show');
      this.showModal = true;
      this.currentItem = this.listOfGoods.find(newItem => newItem.id === product.id);
      this.currentItem.gallery = this.currentItem.gallery.sort((a, b) => a.is_main > b.is_main ? -1 : a.is_main < b.is_main ? 1 : 0);
      this.currentImg = this.currentItem.gallery.find(({is_main}) => !!is_main).image;
      this.images = this.imagesArr(product)
    },

    handleHover(hovered) {
      this.isHovered = hovered;
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    showBtn(item) {
      this.showModal = true;
      this.currentItem = this.listOfGoods.find(newItem => newItem.id === item.id);
      this.currentItem.gallery = this.currentItem.gallery.sort((a, b) => a.is_main > b.is_main ? -1 : a.is_main < b.is_main ? 1 : 0);
      this.currentImg = this.currentItem.gallery.find(({is_main}) => !!is_main).image;
      this.images = this.imagesArr(item)
    },
    shopGoods() {
      this.$http.get(API_ROOT + `/api/shop/goods`)
        .then((res) => {
          this.storeData = res.data;
          this.listOfGoods = res.data.data;
          this.currentPage = res.data.current_page;
          this.perPage = res.data.per_page;
          this.total = res.data.total;
          this.totalPages = Math.ceil(this.total / this.perPage);
        })
        .catch((err) => {
          console.log(err);
        })
    },
    buyGood(productId,buyNow=false) {
      const params = { good_id: productId };
      const basketId = localStorage.getItem('basket_id');
      if (!!basketId) {
        params['basket_id'] = basketId;
      }
      if (localStorage.getItem("token") || localStorage.getItem("session_id")) {
      this.$http.post(`${API_ROOT}/api/shop/basket/add`, params)
        .then(({ body }) => {
          if (!basketId) {
            localStorage.setItem('basket_id', body.data.id);
          }

          this.showModalSuccessfullyAddToBasket = true
          if(buyNow){
            this.$router.push('/store/basket')
          }
          else{
            this.showModalSuccessfullyAddToBasket = true
          }
        })
        .then(() => this.quantityGoodsInBasket())
        .catch(err => console.error(err));}
      else {
        Vue.toastr({
          description: this.$t('add_product'),
          type: 'error'
        })
      }
    },
    quantityGoodsInBasket() {
      if (localStorage.getItem('basket_id')) {
        this.$http.get(API_ROOT + `/api/shop/basket/goods?basket_id=${localStorage.getItem('basket_id')} `)
          .then((res) => {
            this.basketData = res.data.data;
          })
          .catch(err => {
            Vue.toastr({
              message: 'Error',
              description: err.message,
              type: 'error'
            })
          })
      }
    },
    userOrders() {
      this.$http.get(API_ROOT + `/api/shop/orders`)
        .then((res) => {
          console.log("userOrders");
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
    },
    closeModalWindow() {
      this.showModal = false;
      this.currentImg = "";
      this.images = [];
    },
    activeImg(img) {
      return this.currentImg === img;
    },
    setAltImg(event) {
      event.target.src = this.noPhoto;
    },
    mainImage(item) {
      for (let i = 0; i < item.gallery.length; i++) {
          if (item.gallery[i].is_main === 1) {
            return item.gallery[i].image
          }
      }
    },
    imagesArr(item) {
      let newArr = []
      for (let i = 0; i < item.gallery.length; i++) {
        newArr.push(item.gallery[i].image)
      }

      return newArr
    },
    showNextImage() {
      let currentIndex = this.images.findIndex(img => img === this.currentImg)
      if (currentIndex === this.images.length - 1) {
        this.currentImg = this.images[0]
      } else {
        this.currentImg = this.images[currentIndex + 1]
      }
    },
    showPrevImage() {
      let currentIndex = this.images.findIndex(img => img === this.currentImg)
      if (currentIndex === 0) {
        this.currentImg = this.images[this.images.length - 1]
      } else {
        this.currentImg = this.images[currentIndex - 1]
      }
    }
  },
  mounted() {
    this.shopGoods();
    this.quantityGoodsInBasket();
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'show') {
        this.currentModalItem = null;
      }
    });
  },
  computed: {
    countOfGoods() {
      let count = 0;

      for (let i = 0; i < this.basketData.length; i++) {
        count += this.basketData[i].pivot.count;
      }

      return count;
      localStorage.setItem('basket_count', count)

      return count
    },
    activeUserCoins() {
      if (localStorage.getItem('coins')) {
        return this.userCoins = localStorage.getItem('coins');
      } else {
        return this.userCoins = 0;
      }
    }
  }
}
</script>

<style scoped>

</style>
